import React, { useState } from "react";

import useSWR from "swr";
import {useRouter} from "next/router";
import dynamic from 'next/dynamic'

import DashboardBusinesses from '@/components/admin-dashboard/listings'

import AdminLayout from "@/layouts/admin"
import checkIfAdmin from "@/lib/checks/checkIfAdmin"
import useSession from "hooks/useSession";
import { GetServerSidePropsContext } from "next";
import Loader from "@/components/elements/spinner";
import SignInModal from '@/components/modals/signin'

export default function AdminBusinesses() {
    const router = useRouter();
    const session = useSession();
    if (session) window.location.pathname = '/'
    return (
        <div className="px-20">
            <SignInModal onRequestClose={()=>{}} onRequestSignUpFlow={()=>{}}/>
        </div>
    )
}
